import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiBasicParams, ResponseListApi } from '@core/models/interfaces/common';
import { LeadSource } from '@core/models/interfaces/lead';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LeadSourceService {
  private baseUrl = `${environment.API_URL}/crm/lead-sources`;

  constructor(private httpClient: HttpClient) {}

  getSources(params?: ApiBasicParams) {
    return this.httpClient.get<ResponseListApi<LeadSource>>(this.baseUrl, { params: { ...params } });
  }

  getSubSources(id: string, params?: ApiBasicParams) {
    return this.httpClient.get<ResponseListApi<LeadSource>>(`${this.baseUrl}/${id}/sub-sources`, {
      params: { ...params },
    });
  }
}
