import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseApi, ResponseListApi } from '@core/models/interfaces/common';
import { CreatedProductTypeParams, ProductType, ProductTypeParams } from '@core/models/interfaces/product-type';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductTypeService {
  baseUrl = `${environment.API_URL}/crm/product-type`;

  constructor(private http: HttpClient) {}

  getProductTypes(params?: ProductTypeParams) {
    let queryParams = new HttpParams();

    if (params?.keyword !== undefined) {
      queryParams = queryParams.append('page', params.keyword);
    }

    if (params?.companyId !== undefined) {
      queryParams = queryParams.append('companyId', params.companyId);
    }

    if (params?.page !== undefined) {
      queryParams = queryParams.append('page', params.page);
    }

    if (params?.size !== undefined) {
      queryParams = queryParams.append('size', params.size);
    }

    if (params?.sorts !== undefined) {
      queryParams = queryParams.append('sorts', params.sorts);
    }

    return this.http.get<ResponseListApi<ProductType>>(this.baseUrl, {
      params: queryParams
    });
  }

  createProductType(body: CreatedProductTypeParams) {
    return this.http.post<ResponseApi<CreatedProductTypeParams>>(this.baseUrl, body);
  }

  deleteProductType(id: string) {
    return this.http.delete(`${this.baseUrl}/${id}`);
  }

  updateProductType(id: string, body: CreatedProductTypeParams) {
    return this.http.put<ResponseApi<CreatedProductTypeParams>>(`${this.baseUrl}/${id}`, body);
  }

  getProductTypeDetails(id: string) {
    return this.http.get<ResponseApi<CreatedProductTypeParams>>(`${this.baseUrl}/${id}`);
  }
}
