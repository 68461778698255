import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiBasicParams, ResponseApi, ResponseListApi } from '@core/models/interfaces/common';
import { GroupItem } from '@core/models/interfaces/user';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TeamService {
  private baseUrl = `${environment.API_URL}/crm/teams`;

  constructor(private httpClient: HttpClient) {}

  getTeams(params?: ApiBasicParams) {
    let queryParams = new HttpParams();
    if (params?.page != undefined) {
      queryParams = queryParams.append('page', params.page);
    }

    if (params?.size != undefined) {
      queryParams = queryParams.append('size', params.size);
    }

    if (params?.sorts != undefined) {
      queryParams = queryParams.append('sorts', params.sorts);
    }

    return this.httpClient.get<ResponseListApi<GroupItem>>(this.baseUrl, { params: queryParams });
  }

  createTeam(name: string) {
    return this.httpClient.post<ResponseApi<GroupItem>>(this.baseUrl, { name });
  }

  deleteTeam(id: string) {
    return this.httpClient.delete<ResponseApi<GroupItem>>(`${this.baseUrl}/${id}`);
  }

  updateTeam(id: string, body: { name: string }) {
    return this.httpClient.put<ResponseApi<GroupItem>>(`${this.baseUrl}/${id}`, body);
  }

  getTeamById(id: string) {
    return this.httpClient.get<ResponseApi<GroupItem>>(`${this.baseUrl}/${id}`);
  }

  getTeamsByUserIds() {
    return this.httpClient.get<ResponseListApi<GroupItem>>(`${this.baseUrl}/by-users`)
  }
}
