import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiBasicParams, ResponseApi, ResponseListApi } from '@core/models/interfaces/common';
import { Company } from '@core/models/interfaces/company';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  baseUrl = `${environment.API_URL}/crm/companies`;

  constructor(private http: HttpClient) {}

  getCompanies(params?: ApiBasicParams) {
    let queryParams = new HttpParams();
    if (params?.keyword !== undefined) {
      queryParams = queryParams.append('keyword', params.keyword);
    }

    if (params?.page !== undefined) {
      queryParams = queryParams.append('page', params.page);
    }

    if (params?.size !== undefined) {
      queryParams = queryParams.append('size', params.size);
    }

    if (params?.sorts !== undefined) {
      queryParams = queryParams.append('sorts', params.sorts);
    }

    return this.http.get<ResponseListApi<Company>>(this.baseUrl, {
      params: queryParams
    });
  }

  createCompany(body: { name: string }) {
    return this.http.post<ResponseApi<Company>>(this.baseUrl, body);
  }

  deleteCompany(id: string) {
    return this.http.delete(`${this.baseUrl}/${id}`);
  }

  updateCompany(id: string, body: { name: string }) {
    return this.http.put<ResponseApi<Company>>(`${this.baseUrl}/${id}`, body);
  }

  getCompanyById(id: string) {
    return this.http.get<ResponseApi<Company>>(`${this.baseUrl}/${id}`);
  }
}
