import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseApi, ResponseListApi } from '@core/models/interfaces/common';
import { CreatedProductParams, Product, ProductParams } from '@core/models/interfaces/product';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  baseUrl = `${environment.API_URL}/crm/products`;

  constructor(private http: HttpClient) {}

  getProducts(params?: ProductParams) {
    let queryParams = new HttpParams();

    if (params?.productTypeId !== undefined) {
      queryParams = queryParams.append('productTypeId', params.productTypeId);
    }

    if (params?.keyword !== undefined) {
      queryParams = queryParams.append('keyword', params.keyword);
    }

    if (params?.page !== undefined) {
      queryParams = queryParams.append('page', params.page);
    }

    if (params?.size !== undefined) {
      queryParams = queryParams.append('size', params.size);
    }

    if (params?.sorts !== undefined) {
      queryParams = queryParams.append('sorts', params.sorts);
    }

    return this.http.get<ResponseListApi<Product>>(this.baseUrl, {
      params: queryParams
    });
  }

  createProduct(body: CreatedProductParams) {
    return this.http.post<ResponseApi<Product>>(this.baseUrl, body);
  }

  deleteProduct(id: string) {
    return this.http.delete(`${this.baseUrl}/${id}`);
  }

  updateProduct(id: string, body: CreatedProductParams) {
    return this.http.put<ResponseApi<Product>>(`${this.baseUrl}/${id}`, body);
  }

  getProductById(id: string) {
    return this.http.get<ResponseApi<Product>>(`${this.baseUrl}/${id}`);
  }
}
